import axios from 'axios'
import { useState } from 'react'

function PasswordReset() {
  const query = window.location.search
  const params = new URLSearchParams(query)
  const token = params.get("token") || ""
  const [passwordResetStatus, setPasswordResetStatus] = useState("")
  function submit(ev) {
    ev.preventDefault();
    console.log(token)
    const formData = new FormData(ev.target)
    const body = Object.fromEntries(formData.entries())
    axios.post('/api/v1/reset_password',
      body,
      {
        headers: {
          "Authorization": "Bearer " + token
        }
      }
    ).catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      setPasswordResetStatus("更改密碼失敗，請聯絡鑑定助理管理員")
      console.log(error.config);
    }).then((response) => {
      if (response.status === 200) {
        setPasswordResetStatus("更改密碼完成，請以新密碼登入鑑定助理 app")
      } else {
        setPasswordResetStatus("更改密碼失敗，請聯絡鑑定助理管理員")
      }
    });
  }
  let payloadStr = token.split('.')[1]
  let tokenInvalid = false
  let payload = {}
  if (payloadStr === undefined) {
    tokenInvalid = true
  } else {
    try {
      payloadStr = atob(payloadStr)
      payload = JSON.parse(payloadStr)
    } catch(error) {
      tokenInvalid = true
      console.log(error)
    }
  }

  return (
    <div>
      <h1>鑑定助理帳號服務 - 更改密碼</h1>
      <div>{tokenInvalid ? "更改密碼連結無效，請聯絡鑑定助理管理員" : "更改密碼帳號：" + payload.username }</div>
      <div>
        <img src="/bca-logo.png" height={200} width={200}></img>
      </div>
      <form onSubmit={submit}>
        <div>請輸入要更改的密碼</div>
        <div>{passwordResetStatus}</div>
        <div><input type="password" name="NewPassword"/></div>
        <div>
          <button type="submit">
            更改密碼
          </button>
        </div>
      </form>
    </div>
  )
}

export default PasswordReset